import { Routes, Route } from 'react-router-dom';
import Whoops404 from './components/4_Whoops404/Whoops404';
import AllProject from './components/AllProject/AllProject';
import About from './components/pagesMenuLinks/About/About';
import Price from './components/pagesMenuLinks/Price/Price';
import Contact from './components/pagesMenuLinks/Contact/Contact';
import Article1 from './components/pagesMenuLinks/Articles/1_Article.jsx';
import Article2 from './components/pagesMenuLinks/Articles/2_Article.jsx';
import Article3 from './components/pagesMenuLinks/Articles/3_Article.jsx';
// en lang
import AllProjectEn from './LangEn/AllProject/AllProject';
import AboutEn from './LangEn/pagesMenuLinks/About/About';
import PriceEn from './LangEn/pagesMenuLinks/Price/Price';
import ContactEn from './LangEn/pagesMenuLinks/Contact/Contact';
import Article1En from './LangEn/pagesMenuLinks/Articles/1_Article';
import Article2En from './LangEn/pagesMenuLinks/Articles/2_Article';
import Article3En from './LangEn/pagesMenuLinks/Articles/3_Article';

import './App.css';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<AllProject />} />
        <Route path="/about" element={<About />} />
        <Route path="/price" element={<Price />} />
        <Route path="/article1" element={<Article2 />} />
        <Route path="/article2" element={<Article3 />} />
        <Route path="/article3" element={<Article1 />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/en/main" element={<AllProjectEn />} />
        <Route path="/en/about" element={<AboutEn />} />
        <Route path="/en/price" element={<PriceEn />} />
        <Route path="/en/contact" element={<ContactEn />} />
        <Route path="/en/article1" element={<Article2En />} />
        <Route path="/en/article2" element={<Article3En />} />
        <Route path="/en/article3" element={<Article1En />} />
        <Route path="*" element={<Whoops404 />} />
      </Routes>
    </>
  );
}

export default App;
