import { MdOutlineEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import './Email.css';

function Email() {
  return (
    <div className="email">
      <Link to={'mailto:rompar@op.pl'}>
        <p className="email__link">
          <MdOutlineEmail /> rompar@op.pl
        </p>
      </Link>
    </div>
  );
}
export default Email;
