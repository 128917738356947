import { Link } from 'react-router-dom';
import Img_phone from '../../../img/links/telephone2.png';
import { FiPhoneCall } from 'react-icons/fi';
import './LinkPhone.css';

function Phone() {
  return (
    <div className="header__phone">
      <Link to={'tel:+48662401213'} className="header__phone">
        <img
          title="phone"
          src={Img_phone}
          alt="phone"
          className="header__phone-img"
        />
        <h3 className="header-phone-item">+48 662401213</h3>
      </Link>
    </div>
  );
}
export function PhoneIcons() {
  return (
    <div className="header__phone">
      <Link to={'tel:+48662401213'} className="FiPhoneCall">
        <FiPhoneCall />
      </Link>

      <Link to={'tel:+48662401213'} className="">
        <h3 className="header-phone-item">+48 662401213</h3>
      </Link>
    </div>
  );
}
export function PhoneOnly() {
  return (
    <div className="">
      <Link to={'tel:+48662401213'} className="">
        <p className="header__phone-only">+48 662401213</p>
      </Link>
    </div>
  );
}

export default Phone;
