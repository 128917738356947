import React, { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import foto from '../../../img/about/about.jpg';
import './About.css';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  const closeLang = () => {
    setLang(false);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>PL</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang closeLang={closeLang} isLang={isLang}>
            {isLang ? (
              <Link to="/en/about">EN</Link>
            ) : (
              <Link to="/about">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function AboutTable() {
  return (
    <div className="about">
      <div className="about-title">
        <h1>O nas</h1>
      </div>
      <div className="container about-container">
        <div className="about__content">
          <div className="about__content-text">
            <h2>
              Cześć, drodzy klienci, jesteśmy firmą sprzątającą "Rompar clean"
            </h2>
            <p>
              Firma <strong>"Rompar clean"</strong> zajmuje niezwykle ważną
              pozycję na rynku, gdyż oferuje usługi niezbędne w życiu
              codziennym. Czyszczenie mebli w domu jest trudnym zadaniem,
              szczególnie jeśli jest w nich dużo brudu. Dlatego warto skorzystać
              z usług profesjonalistów, którzy wiedzą, jak obchodzić się z
              każdym rodzajem tapicerki i zadbać o ich prawidłowe czyszczenie.
            </p>
            <p>
              Nasza firma specjalizuje się w profesjonalnym czyszczeniu
              tapicerki, w tym sof, krzeseł, foteli, łóżek, zasłon, materacy,
              wykładzin, dywanów, wnętrz samochodów. Nasze usługi kierujemy
              zarówno do klientów indywidualnych, jak i firm, które potrzebują
              usługi czyszczenia mebli w biurach, hotelach, restauracjach i
              innych miejscach użyteczności publicznej.
            </p>
            <div className="about__content-img">
              <img src={foto} alt="О нас" />
            </div>

            <p>
              "Rompar clean" gwarantuje jakość każdego procesu czyszczenia, a
              także bezpieczeństwo i wygodę dla naszych klientów. Używamy
              wyłącznie najwyższej jakości narzędzi i środków czyszczących, aby
              zapewnić skuteczne i bezpieczne sprzątanie. Nasza praca opiera się
              na indywidualnym podejściu do każdego zamówienia, aby zapewnić jak
              najlepszy efekt dla każdego mebla i klienta.
            </p>
            <p>
              Nasza firma oferuje szeroki zakres usług takich jak czyszczenie
              powierzchni, usuwanie plam, dezodoryzacja, dezynfekcja, odkurzanie
              i inne. Każdą usługę dopasowujemy do indywidualnych potrzeb
              Klienta, tak aby zapewnić jak najlepszy efekt.
            </p>
            <p>
              Czyszczenie mebli tapicerowanych może być drogie, jednak nasza
              firma oferuje konkurencyjne ceny, które różnią się w zależności od
              rozmiaru i rodzaju mebli. Nasze ceny są uczciwe i przejrzyste, co
              oznacza, że ​​klienci nie muszą się martwić o ukryte koszty.
            </p>
            <p>
              Naszą misją jest zapewnienie maksymalnego komfortu i satysfakcji
              naszym klientom, przy jednoczesnym zachowaniu bezpieczeństwa
              ekologicznego i wysokiej jakości usług. Wybierz "Rompar clean",
              aby cieszyć się nienaganną czystością swojego domu, mieszkania czy
              samochodu!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function About() {
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <ButtomsLang />
        <WhatsApp />
        <AboutTable />
        <Footer />
      </div>
    </>
  );
}
export default About;
