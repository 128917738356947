import React, { useState } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Button, ButtonLang } from '../../../UI/Buttons/Buttons';
import Lang from '../../../UI/Lang/Lang';
import Header from '../../1_Header/Container/Header';
import Footer from '../../3_Footer/Footer';
import LinkSocial from '../../1_Header/SocialLinks/LinkSocial';
import foto from '../../../img/contact/plan.jpg';
import './Contact.css';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function ButtomsLang() {
  const [isLang, setLang] = useState(false);
  const changeLang = () => {
    setLang((isLang) => !isLang);
  };
  return (
    <>
      <div
        onClick={changeLang}
        className={isLang ? 'back-drop' : 'hidden'}
      ></div>
      <div>
        <Lang lang="lang" marginTop="20px">
          <Button onClick={changeLang} select="select">
            <span>EN</span>
            <AiOutlineCaretDown className={isLang ? 'vectorup' : 'none'} />
          </Button>
          <ButtonLang isLang={isLang}>
            {isLang ? (
              <Link to="/contact">PL</Link>
            ) : (
              <Link to="/contact">RU</Link>
            )}
          </ButtonLang>
        </Lang>
      </div>
    </>
  );
}

function ContactTable() {
  return (
    <div className="contact">
      <div className="contacts-title">
        <h1>CONTACT</h1>
      </div>
      <div className="container contacts__container">
        <div className="contacts__item">
          <div className="contacts__item-phone">
            <p>Phone number::</p>
            <Link to={'tel:+48662401213'}>
              <h2 className="header-phone-item">+48 662401213</h2>
            </Link>
          </div>
          <div className="contacts__item-phone">
            <p>Email:</p>
            <Link to={'mailto:rompar@op.pl'}>
              <h2 className="header-phone-item">rompar@op.pl</h2>
            </Link>
          </div>

          <div className="contacts__item-social">
            <p>We are on social networks:</p>
            <div className="contacts__item-social-links">
              <LinkSocial />
            </div>
          </div>
          <div className="contacts__item-praca">
            <h2>Work plan</h2>
            <img src={foto} alt="foto" />
            <p>We work 7 days a week, from 6:00 a.m. to 7:00 p.m.</p>
            <p>We operate in Gniezno and the surrounding area.</p>
          </div>
          <div className="adress">Gniezno, Poland</div>
        </div>
      </div>
    </div>
  );
}
function Contact() {
  return (
    <div className="body_wrapper">
      <Header />

      <ButtomsLang />
      <ContactTable />
      <WhatsApp />
      <Footer />
    </div>
  );
}
export default Contact;
