import { Link } from 'react-router-dom';
import broom from '../../../img/vacuum-cleaner.png';
import './Logo.css';
function Logo() {
  return (
    <>
      <Link to="/en/main" className="logo">
        <div className="logo2">
          <p className="fname">Rompar</p>
          <p className="sname">clean</p>
          <img src={broom} alt="logo" className="broom" />
        </div>
        {/* <img className="logo__img" src={logo} alt="logo" title="SmellClean" /> */}
      </Link>
    </>
  );
}
export default Logo;
