import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import foto1 from '../../img/articles/article1.png';
import foto2 from '../../img/articles/article2.jpg';
import foto3 from '../../img/articles/article3.jpg';
import './General_img.css';

function Block() {
  return (
    <div className="block">
      <h3>Washing upholstered furniture</h3>
    </div>
  );
}
function BlockOneImgP() {
  return (
    <div>
      <img src={foto1} alt="foto1" className="fotoImg" />
      <p className="special">
        Cleaning upholstered furniture is one of the most important hygiene
        procedures at home and at work. Upholstered furniture is not a place of
        relaxation, but often also a place of work. as an eating surface.
        Therefore, they accumulate a lot of dirt, bacteria and mites that may
        have a negative impact on our health...
      </p>
    </div>
  );
}
function BlockOneCheck() {
  return (
    <div className="check">
      <Link to="/en/article3">More details...</Link>
    </div>
  );
}
function BlockOneFromArticle1() {
  return (
    <div className="block_1">
      <Block />
      <BlockOneImgP />
      <BlockOneCheck />
    </div>
  );
}
function BlockTwo() {
  return (
    <div className="block">
      <h3>Washing mattresses</h3>
    </div>
  );
}
function BlockTwoImgP() {
  return (
    <div>
      <img src={foto2} alt="foto9" className="fotoImg" />
      <p className="special">
        The mattress is one of the most important elements of our bedroom, and
        also the place where we spend about one third of our time. Therefore, it
        is important that our mattress is clean and hygienic. Regularly washing
        mattresses is crucial to maintaining cleanliness and hygiene, and in
        this article you will find some reasons why it is so important...
      </p>
    </div>
  );
}
function BlockTwoCheck() {
  return (
    <div className="check">
      <Link to="/en/article1">More details...</Link>
    </div>
  );
}
function BlockTwoFromArticle2() {
  return (
    <div className="block_2">
      <BlockTwo />
      <BlockTwoImgP />
      <BlockTwoCheck />
    </div>
  );
}
function Block3() {
  return (
    <div className="block">
      <h3>Washing carpets and rugs</h3>
    </div>
  );
}
function BlockThreeImgP() {
  return (
    <div>
      <img src={foto3} alt="foto1" className="fotoImg" />
      <p className="special">
        Washing carpets and rugs is important for the cleanliness and hygiene of
        our homes. Despite regular vacuuming, various types of dirt can
        accumulate on them, affecting not only aesthetics, but also our health.
        In this article, we will present several reasons why washing carpets and
        rugs is so important...
      </p>
    </div>
  );
}
function BlockThreeCheck() {
  return (
    <div className="check">
      <Link to="/en/article2">More details...</Link>
    </div>
  );
}
function BlockOneFromArticle3() {
  return (
    <div className="block_3">
      <Block3 />
      <BlockThreeImgP />
      <BlockThreeCheck />
    </div>
  );
}
function SwiperArticles() {
  return (
    <Swiper
      navigation
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={'auto'}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={{ type: 'fraction' }}
      modules={[EffectCoverflow, Navigation]}
      className="mySwiper"
    >
      <SwiperSlide>
        <BlockOneFromArticle3 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockOneFromArticle1 />
      </SwiperSlide>
      <SwiperSlide>
        <BlockTwoFromArticle2 />
      </SwiperSlide>
    </Swiper>
  );
}
export default SwiperArticles;
